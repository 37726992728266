<template>
	<div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body px-4 py-3">
               <div class="user-account d-flex m-0">
                  <div class="align-self-center user_div mr-3">
                     <img :src="serverS.url_icon == null ? '' : serverS.url_icon" class="rounded user-photo" @error="imageError" />
                  </div>
                  <div class="col align-self-center">
                     <div class="row align-items-center m-0">
                        <div class="col-6">
                           <h1 class="limitador weight-600 color-theme font-30">{{ String(serverS.name).length > 17 ? String(serverS.name).substring(0, 17) +'...' : String(serverS.name) }}</h1>
                           <p class="limitador font-15 mb-1">
                              <strong class="color-theme weight-600 mr-1"><i class="far fa-user font-13 color-theme mr-1"></i> {{ $t("paginaInicial.labelOwner") }}:</strong> {{ serverS.ownerName == null ? $t("premium.booster.unknown") : serverS.ownerName }}
                           </p>
                        </div>
                        <div class="col-6">
                           <p class="limitador font-15 mb-1">
                              <strong class="color-theme weight-600 mr-1"><i class="far fa-star font-13 color-theme mr-1"></i> {{ $t("premium.booster.currentPlan") }}:</strong> {{ payment.subscriptionPack == null ? $t("premium.booster.unknown") : payment.subscriptionPack }}
                           </p>
                           <p class="limitador font-15 mb-1">
                              <strong class="color-theme weight-600 mr-1"><i class="far fa-calendar font-13 color-theme mr-1"></i> {{ $t("premium.booster.cancelAfterExpires") }}:</strong> {{ payment.subscriptionExpiration == null ? $t("premium.booster.unknown") : new Date(payment.subscriptionExpiration.year +'/'+ payment.subscriptionExpiration.monthValue +'/'+ payment.subscriptionExpiration.dayOfMonth).toLocaleDateString() }}
                           </p>
                           <p class="limitador font-15 mb-1 text-capitalize">
                              <strong class="color-theme weight-600 mr-1"><i class="far fa-sync font-13 color-theme mr-1"></i> Status:</strong> {{ payment.subscriptionStatus == null ? $t("premium.booster.unknown") : payment.subscriptionStatus }}
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body p-relative px-4">
               <div class="text-center h-100">
                  <div class="d-none d-xl-flex overflow-auto">
                     <div class="col-4 column-pack align-self-center mb-2" style="min-width: 200px;">
                        <h4 class="tp-hero__title-lg text-center mb-4 color-theme">{{ $t("premium.config.title")}}</h4>
                     </div>
                     <div class="col-2 column-pack align-self-center mb-2" :class="payment.subscriptionPack == 'Free' ? '' : 'px-2'">
                        <div class="ratio ratio-1x1 border rounded" :class="payment.subscriptionPack == 'Free' ? 'active border-theme' : 'border-secondary'">
                           <img class="rounded" src="@/assets/images/planos/free.png" alt="Banner">
                        </div>
                     </div>
                     <div class="col-2 column-pack align-self-center mb-2" :class="payment.subscriptionPack == 'Basic' ? '' : 'px-2'">
                        <div class="ratio ratio-1x1 border rounded" :class="payment.subscriptionPack == 'Basic' ? 'active border-theme' : 'border-secondary'">
                           <img class="rounded" src="@/assets/images/planos/basic.png" alt="Banner">
                        </div>
                     </div>
                     <div class="col-2 column-pack align-self-center mb-2" :class="payment.subscriptionPack == 'Standard' ? '' : 'px-2'">
                        <div class="ratio ratio-1x1 border rounded" :class="payment.subscriptionPack == 'Standard' ? 'active border-theme' : 'border-secondary'">
                           <img class="rounded" src="@/assets/images/planos/standard.png" alt="Banner">
                        </div>
                     </div>
                     <div class="col-2 column-pack align-self-center mb-2" :class="payment.subscriptionPack == 'Ultimate' ? '' : 'px-2'">
                        <div class="ratio ratio-1x1 border rounded" :class="payment.subscriptionPack == 'Ultimate' ? 'active border-theme' : 'border-secondary'">
                           <img class="rounded" src="@/assets/images/planos/ultimate.png" alt="Banner">
                        </div>
                     </div>
                  </div>
                  <div class="d-flex overflow-auto">
                     <div class="col-4 column-pack" style="min-width: 200px;">
                        <div class="my-3 limitador title-grid">{{ $t("premium.config.why").toUpperCase() }}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-comments-alt font-13 mr-2"></i>{{ $t("premium.config.textGrids.welcome")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-poll font-13 mr-2"></i>{{ $t("premium.config.textGrids.customPoll")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-gift font-13 mr-2"></i>{{ $t("premium.config.textGrids.monthly")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-list-ul font-13 mr-2"></i>{{ $t("premium.config.textGrids.commands")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-comments-alt font-13 mr-2"></i>{{ $t("premium.config.textGrids.customCmd")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-code font-13 mr-2"></i>{{ $t("premium.config.textGrids.ticket")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-clipboard font-13 mr-2"></i>{{ $t("premium.config.textGrids.customWl")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-comment-alt-dots font-13 mr-2"></i>{{ $t("premium.config.textGrids.privateMessage")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-images font-13 mr-2"></i>{{ $t("premium.config.textGrids.customImg")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-shopping-cart font-13 mr-2"></i>{{ $t("premium.config.textGrids.shop")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-link font-13 mr-2"></i>{{ $t("premium.config.textGrids.shopWebhook")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-image font-13 mr-2"></i>{{ $t("premium.config.textGrids.shopBanners")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-copyright font-13 mr-2"></i>{{ $t("premium.config.textGrids.shopCustom")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-badge-check font-13 mr-2"></i>{{ $t("premium.config.textGrids.shopBadge")}}</div>
                        <hr class="my-1">
                        <div class="my-2 limitador"><i class="fal fa-comment-alt-dollar font-13 mr-2"></i>{{ $t("premium.config.textGrids.shopCommands")}}</div>
                        <hr class="my-1">
                        <div class="my-3"></div>
                     </div>
                     <div class="col-2 column-pack" :class="payment.subscriptionPack == 'Free' ? 'active' : ''">
                        <div class="my-3 limitador title-grid">{{ $t("premium.config.free").toUpperCase() }}</div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="m-2 btnPag-disabled" v-if="payment.subscriptionPack == 'Free'"><i class="far fa-check mr-2"></i>{{ $t("premium.booster.active").toUpperCase() }}</div>
                     </div>
                     <div class="col-2 column-pack" :class="payment.subscriptionPack == 'Basic' ? 'active' : ''">
                        <div class="my-3 limitador title-grid">{{ $t("premium.config.basic").toUpperCase() }}</div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="m-2 btnPag-bg" @click="cancelarAssinatura" v-if="payment.subscriptionPack == 'Basic' && payment.subscriptionStatus == 'active'">
                           <i class="far fa-ban font-14 mr-2"></i>{{ $t("premium.booster.cancel") }}
                        </div>
                        <div class="m-2 btnPag-disabled" v-else-if="payment.subscriptionPack == 'Basic' && payment.subscriptionStatus == 'pending'">
                           <i class="far fa-clock font-14 mr-2"></i>{{ $t("premium.booster.process") }}
                        </div>
                        <div class="m-2 btnPag" v-else-if="payment.subscriptionPack == 'Basic' && payment.subscriptionStatus == 'cancelAfterExpires'">
                           {{ $t("premium.booster.cancelAfterExpires") }} {{ payment.subscriptionExpiration == null ? $t("premium.booster.unknown") : new Date(payment.subscriptionExpiration.year +'/'+ payment.subscriptionExpiration.monthValue +'/'+ payment.subscriptionExpiration.dayOfMonth).toLocaleDateString() }}
                        </div>
                        <div class="m-2 btnPag" @click="assinatura('Basic')" v-else-if="payment.subscriptionStatus != 'pending'">R$ 14,90</div>
                     </div>
                     <div class="col-2 column-pack" :class="payment.subscriptionPack == 'Standard' ? 'active' : ''">
                        <div class="my-3 limitador title-grid">{{ $t("premium.config.standard").toUpperCase() }}</div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-dhas fas fa-times"></i></div>
                        <hr class="my-1">
                        <div class="m-2 btnPag-bg" @click="cancelarAssinatura" v-if="payment.subscriptionPack == 'Standard' && payment.subscriptionStatus == 'active'">
                           <i class="far fa-ban font-14 mr-2"></i>{{ $t("premium.booster.cancel") }}
                        </div>
                        <div class="m-2 btnPag-disabled" v-else-if="payment.subscriptionPack == 'Standard' && payment.subscriptionStatus == 'pending'">
                           <i class="far fa-clock font-14 mr-2"></i>{{ $t("premium.booster.process") }}
                        </div>
                        <div class="m-2 btnPag" v-else-if="payment.subscriptionPack == 'Standard' && payment.subscriptionStatus == 'cancelAfterExpires'">
                           {{ $t("premium.booster.cancelAfterExpires") }} {{ payment.subscriptionExpiration == null ? $t("premium.booster.unknown") : new Date(payment.subscriptionExpiration.year +'/'+ payment.subscriptionExpiration.monthValue +'/'+ payment.subscriptionExpiration.dayOfMonth).toLocaleDateString() }}
                        </div>
                        <div class="m-2 btnPag" @click="assinatura('Standard')" v-else-if="payment.subscriptionStatus != 'pending'">R$ 29,90</div>
                     </div>
                     <div class="col-2 column-pack" :class="payment.subscriptionPack == 'Ultimate' ? 'active' : ''">
                        <div class="my-3 limitador title-grid">{{ $t("premium.config.ultimate").toUpperCase() }}</div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="my-2"><i class="icon-has fas fa-check"></i></div>
                        <hr class="my-1">
                        <div class="m-2 btnPag-bg" @click="cancelarAssinatura" v-if="payment.subscriptionPack == 'Ultimate' && payment.subscriptionStatus == 'active'">
                           <i class="far fa-ban font-14 mr-2"></i>{{ $t("premium.booster.cancel") }}
                        </div>
                        <div class="m-2 btnPag-disabled" v-else-if="payment.subscriptionPack == 'Ultimate' && payment.subscriptionStatus == 'pending'">
                           <i class="far fa-clock font-14 mr-2"></i>{{ $t("premium.booster.process") }}
                        </div>
                        <div class="m-2 btnPag" v-else-if="payment.subscriptionPack == 'Ultimate' && payment.subscriptionStatus == 'cancelAfterExpires'">
                           {{ $t("premium.booster.cancelAfterExpires") }} {{ payment.subscriptionExpiration == null ? $t("premium.booster.unknown") : new Date(payment.subscriptionExpiration.year +'/'+ payment.subscriptionExpiration.monthValue +'/'+ payment.subscriptionExpiration.dayOfMonth).toLocaleDateString() }}
                        </div>
                        <div class="m-2 btnPag" @click="assinatura('Ultimate')" v-else-if="payment.subscriptionStatus != 'pending'">R$ 49,90</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalAssinatura -->
      <div class="modal fade" id="modalAssinatura" aria-labelledby="modalAssinaturaLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-body">
                  <div class="card mb-2">
                     <div class="card-body d-flex">
                        <h1 class="font-18 mb-0"><i class="far fa-star color-theme font-15 mr-2"></i>{{ modal.pack }}</h1>
                     </div>
                  </div>
                  <div class="card mb-1 cursor-pointer" @click="assinaturaStripe(modal.pack)">
                     <div class="card-body p-3 d-flex align-items-center">
                        <img src="@/assets/images/stripe.png" height="50" width="50" />
                        <div class="ml-4">
                           <h1 class="font-18 mb-0">Via <strong>Stripe</strong></h1>
                           <p class="font-14 mb-0">Global</p>
                        </div>
                     </div>
                  </div>
                  <div class="card mb-0 cursor-pointer" @click="assinaturaMercadoPago(modal.pack)">
                     <div class="card-body p-3 d-flex align-items-center">
                        <img src="@/assets/images/mercadoPago.png" height="50" width="50" />
                        <div class="ml-4">
                           <h1 class="font-18 mb-0">Via <strong>Mercado Pago</strong></h1>
                           <p class="font-14 mb-0">Brasil</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Premium',
   data: function () {
		return {
         payment: {'subscriptionStatus': null, 'subscriptionExpiration': null, 'subscriptionPack': 'Free'},
         modal: {'pack': null}
      }
   },
   computed: {
		...mapState({
         dadosUsuario: state => state.dadosUsuario,
			urlRest: state => state.urlRest,
			serverS: state => state.serverS
		}),
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      getStatus : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.payment = {'subscriptionStatus': null, 'subscriptionExpiration': null, 'subscriptionPack': 'Free'}

         this.$axios({
            method: 'get',
            url: this.urlRest +'payment/getpaymentstatus',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.payment = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      assinatura : function (pack) {
         this.modal.pack = pack
         $("#modalAssinatura").modal('show')
      },
      assinaturaMercadoPago : function (pack) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         $("#modalAssinatura").modal('hide')

         this.$axios({
            method: 'post',
            url: this.urlRest +'payment/mercadopago',
            params: {
               email: this.dadosUsuario.email,
               pack: pack,
               idServer: this.serverS.id_s
            }
         }).then(response => {
            window.open(response.data.init_point)
            this.getStatus()

            Swal.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.premium.payment.title"),
               text: this.$t("serverSettings.swal.premium.payment.desc")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      assinaturaStripe : function (pack) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         $("#modalAssinatura").modal('hide')

         this.$axios({
            method: 'post',
            url: this.urlRest +'payment/stripePgto',
            params: {
               email: this.dadosUsuario.email,
               pack: pack,
               idServer: this.serverS.id_s
            }
         }).then(response => {
            window.open(response.data)
            this.getStatus()

            Swal.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.premium.payment.title"),
               text: this.$t("serverSettings.swal.premium.payment.desc")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      cancelarAssinatura : function () {
         Swal.fire({
            icon: 'warning',
            title: this.$t("premium.booster.cancel"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
      
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'payment/cancelsubscription',
                  params: {
                     idServer: this.serverS.id_s
                  }
               }).then(() => {
                  this.getStatus()

                  Swal.fire({
                     icon: 'success',
                     title: this.$t("serverSettings.swal.order"),
                     text: this.$t("serverSettings.swal.premium.subs.cancel.ended") + new Date(this.serverS.donateExpiration.year +'/'+ this.serverS.donateExpiration.monthValue +'/'+ this.serverS.donateExpiration.dayOfMonth).toLocaleDateString()
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')
      
                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t("serverSettings.swal.expired")
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Error: ' + error.response.status
                        });
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      }
   },
   mounted() {
      this.getStatus()
   }
}

</script>

<style scoped>

.suport-us {
   width: 250px
}

.tp-hero__title-lg {
	font-weight: 600;
	font-size: 45px;
	color: #fff;
}

.btnPag,
.btnPag-bg,
.btnPag-disabled {
   display: inline-block;
   padding: 14px 7px;
   width: calc(100% - 16px);
   max-width: 150px;
   font-size: 16px;
   font-weight: 700;
   border: 1px dashed var(--primary-color);
   cursor: pointer;
   position: relative;
   background-color: transparent;
   overflow: hidden;
   z-index: 1;
   border-radius: 5px;
   text-align: center;
   text-transform: capitalize;
}

.btnPag-disabled,
.btnPag-bg {
   background-color: var(--primary-color);
   color: #eee;
}

.btnPag-disabled {
   cursor: auto !important;
}

.btnPag::before,
.btnPag-bg::before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   transform: translateX(-100%);
   transition: all .3s;
   z-index: -1;
}

.btnPag::before {
   background-color: var(--primary-color);
}

.btnPag-bg::before {
   background-color: var(--card-color);
}

.btnPag:hover::before,
.btnPag-bg:hover::before {
   transform: translateX(0);
}

.title-grid {
   font-weight: 700;
   font-size: 15px;
   margin-bottom: 15px;
   color: var(--primary-color);
}

.icon-has {
   color: rgb(0, 192, 0);
}

.icon-dhas {
   color: rgb(255, 8, 0);
}

.column-pack {
   min-width: 150px;
   padding: 0;
   border: 1px solid #0000;
   transition: all 0.15s;
}

.column-pack.active,
.column-pack .active {
   border-color: var(--primary-color);
   border-radius: 5px;
   box-shadow: 0 0 10px 0 var(--primary-color);
}

#modalAssinatura .card.cursor-pointer:hover h1 {
   color: var(--primary-color);
}

.user-photo {
   width: 75px !important;
}

</style>